export enum EventName {
  ElementClicked = "Element Clicked",
  FormSubmitted = "Form Submitted",
  SearchFocused = "Search Focused",
  SearchItemSelected = "Search Item Selected",
  SearchStarted = "Search Started",
  SKUCreated = "SKU Created",
  SKUUpdated = "SKU Updated",
  SKUDeleted = "SKU Deleted",
  PageViewed = "Page Viewed",
  UserSignedIn = "User Signed In",
}

export enum ElementType {
  Button = "button",
  Card = "card",
  CTA = "CTA",
  Dropdown = "dropdown",
  Link = "link",
  MenuItem = "menu item",
  Other = "other",
  SideNav = "side nav",
}

export enum ElementLocation {
  MainNavigation = "Main navigation",

  DashboardNavigation = "Dashboard navigation",
  ScorecardsNavigation = "Scorecards navigation",
  ScorecardsCEO = "Scorecards CEO page",
  ScorecardsPLG = "Scorecards PLG page",

  SuppliersSearch = "Suppliers search",
  SupplierPage = "Supplier page",
  SupplierNavigation = "Supplier navigation",

  DocumentSearch = "Document search",
  DocumentList = "Document list",
  DocumentsPage = "Documents page",
  DocumentPage = "Document page",

  CatalogNavigation = "Catalog navigation",
  CatalogSearch = "Catalog search",
  CatalogSideNavigation = "Catalog side navigation",
  CatalogPage = "Catalog page",
  CompaniesPage = "Companies page",
  CompanyPage = "Company page",
  ProductPage = "Product page",
  AddonPage = "Addon page",
  TierPage = "Tier page",
  CategoriesNavigation = "Categories navigation",
  CategoriesSideNavigation = "Categories side navigation",
  CategoriesPage = "Categories page",
  CategoryPage = "Category page",

  ReportsNavigation = "Reports navigation",
  CatalogAuditLog = "Catalog Audit Log",

  DocumentNavigation = "Document navigation",

  // Dropdown menus
  QuickLinksMenu = "Quick Links menu",
  ThemeToggleMenu = "Theme Toggle menu",
  FilterTypesMenu = "Filter Types menu",
  SearchTypesMenu = "Search Types menu",
  SearchFiltersMenu = "Search Filters menu",
  TableNameMenu = "Table name menu",
  StatusMenu = "Status menu",
  VerificationMenu = "Verfication menu",
  RevertMenu = "Revert menu",

  // Forms
  AddonForm = "Addon form",
  CompanyForm = "Company form",
  DocumentUploadForm = "Document Upload form",
  PricingUnitForm = "Pricing Unit form",
  ProductForm = "Product form",
  SaaSConsultantForm = "SaaS Consultant form",
  TierForm = "Tier form",

  // Misc
  ProductBoard = "Product Board",
  Pagination = "Pagination",
}

export enum SearchType {
  Categories = "categories",
  Companies = "companies",
  Catalogs = "catalogs",
  Documents = "documents",
  SpiderJobs = "spider jobs",
  Tasks = "tasks",
  Extractions = "extractions",
}

export type SearchItemProperties = {
  selectionPosition: number;
  selectionType: string;

  contractId?: string;
  contractName?: string;

  entityId?: string;
  entityName?: string;

  documentId?: string;

  category?: string;
};

export type SKUProperites = {
  entityId: string;
  entityName: string;
  entityType?: string;
  skuId?: string;
  skuName: string;
};
